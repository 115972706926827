<template>
  <div class="home">
    <el-button
      type="text"
      class="back-button"
      icon="el-icon-arrow-left"
      @click="$router.replace({ name: 'Home' })"
    ></el-button>
    <img class="bg-banner" :src="activity.coverImage" />
    <div class="index-container">
      <div class="box-rect">
        <div class="index-title">
          <span>{{ activity.name }}</span>
        </div>
        <div>
          <span class="status" :class="activity.status">{{
            statusLable[activity.status] || "未开始"
          }}</span>
        </div>
      </div>
      <div class="box-rect">
        <div>
          <div class="line-height-50">
            <span icon="note" color="black" :size="15"></span>
            <span>主办方：{{ activity.companyName }}</span>
          </div>
          <div class="line-height-50">
            <span icon="time" color="black" :size="15"></span>
            <span
              >时间：{{ activity.saleStartTime }} -
              {{ activity.saleEndTime }}</span
            >
          </div>
          <div class="line-height-50">
            <span icon="time" color="black" :size="15"></span>
            <span>地点：{{ activity.address }}</span>
          </div>
        </div>
      </div>

      <div class="box-rect">
        <div>
          <el-radio-group size="small" v-model="selectedTicketCategory">
            <el-radio-button
              v-for="option in ticketCategoryList"
              :key="option.area"
              :label="option.area"
              >{{ option.area }} ¥{{ option.price }}</el-radio-button
            >
          </el-radio-group>
        </div>
      </div>

      <div class="box-rect">
        <div>报名须知：{{ activity.description }}</div>
      </div>

      <div class="submit-button" v-if="loaded">
        <div @click="applyHandle" v-if="!isOver && !isStock">我要报名</div>
        <div :class="{ disabled: isOver || isStock }" v-else>
          {{ isOver ? "已结束" : "名额已满" }}
        </div>
      </div>
      <div @click="copyLink">
        <img class="share-button" src="../assets/img/share.svg" alt="share" />
      </div>
      <div
        class="rich-html-wrap"
        style="padding-bottom: 50px;"
        v-html="richContent"
      >
        <!-- <img
          v-for="(item, i) in contentImgs"
          :key="i"
          mode="widthFix"
          style="width: 100%;"
          :src="item"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  getTicketDetail,
  getDiscounts,
  getWxSignature,
  getTicketCategoryList
} from "@/api/index.js";
import store from "@/store/index.js";
import wx from "weixin-js-sdk";
import cp from "copy-to-clipboard";

export default {
  name: "Activity",
  data() {
    return {
      ticketCategoryList: [],
      selectedTicketCategory: "",
      activity: {},
      statusLable: {
        NOT_STARTED: "未开始",
        IN_PROGRESS: "进行中",
        COMPLETED: "已结束"
      },
      contentImgs: [],
      richContent: "",
      discountCondition: 0,
      discount: 10,
      isOver: false,
      isStock: false,
      loaded: false,
      // 微信配置信息
      wxConfig: {},
      shareConfig: {},
      activityUrl: ""
    };
  },
  created() {
    this.activityUrl =
      window.config.domain + "/activity/" + this.$route.params.activityId;
    this.getTicketDetail();
  },
  mounted() {
    // 获取微信配置信息
    this.initWxConfig();
  },
  methods: {
    getTicketDetail() {
      getTicketDetail(this.$route.params.activityId).then(res => {
        this.loaded = true;
        this.activity = res;
        store.commit("normal/SET_ACTIVITY", this.activity);
        // this.contentImgs = res.content.split(",");
        this.richContent = decodeURIComponent(res.content || "");

        this.isStock = this.activity.stock >>> 0 === 0;

        this.isOver =
          new Date(this.activity.saleEndTime.replace(/-/g, "/")).getTime() <
          Date.now();

        this.shareConfig = {
          title: res.name, // 分享标题
          desc: res.description, // 分享描述
          link: this.activityUrl, // 分享链接
          imgUrl: res.coverImage,
          type: "link",
          success: function() {
            console.log("share success");
          }
        };

        console.log("get detail");
        console.log(this.shareConfig);

        this.getDiscounts();
        this.getTsRoomLayoutList();
      });
    },
    getDiscounts() {
      getDiscounts(this.activity.id).then(res => {
        if (res.length) {
          this.discountCondition = res[0].condition;
          this.discount = res[0].discount;
        }
      });
    },
    getTsRoomLayoutList() {
      if (!this.activity.enableSeat) {
        this.ticketCategoryList = [
          {
            area: "活动专享",
            price: this.activity.price
          }
        ];
        this.selectedTicketCategory = this.ticketCategoryList[0].area;
      } else {
        getTicketCategoryList(this.activity.roomId).then(res => {
          this.ticketCategoryList = res;
        });
      }
    },
    applyHandle() {
      if (!this.selectedTicketCategory) {
        this.$message.info("请选择门票种类！");
        return;
      }
      let query = {
        // price: this.activity.price || 0,
        // id: this.activity.id || "",
        // stock: this.activity.stock || 0,
        discountCondition: this.discountCondition,
        discount: this.discount,
        activityId: this.activity.id,
        ticketTips: this.activity.metadata.ticketTips,
        ticketCategory: this.selectedTicketCategory
        // roomId: this.activity.roomId,
        // name: this.activity.name,
        // address: this.activity.address,
        // dateTime: `${this.activity.metadata.signInStartTime}-${this.activity.metadata.signInEndTime}`
      };

      this.$router.push({
        name: "BuyTicket",
        query: query
      });
    },
    initWxConfig() {
      // 发送请求获取配置信息
      getWxSignature(this.activityUrl).then(res => {
        this.wxConfig = res;
        this.wxConfig.debug = false;
        this.wxConfig.jsApiList = [
          "onMenuShareAppMessage",
          "onMenuShareTimeline"
        ];
        wx.config(this.wxConfig);

        wx.ready(() => {
          wx.onMenuShareAppMessage(this.shareConfig);
          wx.onMenuShareTimeline(this.shareConfig);
        });
      });
    },
    copyLink() {
      cp(this.activityUrl);
      this.$message.success("已复制分享链接！");
    }
  }
};
</script>

<style lang="stylus" scoped>
.rich-html-wrap {
  /deep/ img {
    max-width: 100%!important;
    height: auto!important;
  }
}
.bg-banner {
  width: 100%;
  z-index: -1;
}

.home {
  background-color: #f8f9ff;
}

.index-container {
  height 100%
  text-align: left;
  margin-top: -10px;
  padding-bottom: 65px;
}

.index-title {
  font-size: 20px;
  font-family: "微软雅黑";
  font-weight: bold;
  margin-top: -5px;
  margin-bottom: 8px;
  color: #111111;
}

.box-rect {
  background-color: #ffffff;
  margin: 10px 0;
  padding: 10px 15px;
  color: #454545;
  font-size: 13px;
  box-shadow: 0 0 2px rgba(0, 0, 0, .04);
}

.box-rect .status {
  display: inline-block;
  color: #ffffff;
  font-size: 11px;
  padding: 0 8px;
  height: 18px;
  line-height 18px;
  border-radius: 9px;
  background-color: #aaaaaa;
  &.NOT_STARTED {
    background-color: #d0011b;
  }
  &.IN_PROGRESS {
    background-color: #57b837;
  }
  &.COMPLETED {
    background-color: #aaaaaa;
  }
}

.line-height-50 .weui-icon {
  margin-right: 8px;
  margin-top: -3px;
}

.box-rect .line-height-50 {
  padding-top: 10px;
}

.box-rect .item-button {
  background-color: #fff2d8;
  border: 1px solid #d8b32f;
  color: #dead4e;
  font-size: 15px;
  display: inline-block;

  height: 25px;
  padding: 0 8px;
  border-radius: 13px;
  margin: 8px 0;
}

.box-rect .title {
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  font-family: "微软雅黑";
  color: #111111;
  margin-bottom: 5px;
}

.price {
  font-weight: bold;
  color: green;
}

.submit-button div.disabled {
  background-image: linear-gradient(90deg, #cecece, #e3e3e3);
  box-shadow: none;
}
</style>
